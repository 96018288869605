import { v4 as uuid } from 'uuid';

export const createInitialPackMappingSetState = () => ({
  id: uuid(),
  name: '',
  mappings: [createInitialPackMappingState()],
});

export const createInitialPackMappingState = () => ({
  id: uuid(),
  skus: [],
  attributeModelUrl: '',
  attributeModel: undefined,
  packCalculatorUrl: '',
  packCalculator: createInitialPackCalculatorState(),
});

export const BIGGEST_PACKS = 'biggestPacks';
export const EVEN_PACKS = 'evenPacks';

export const createInitialPackCalculatorState = () => ({
  id: uuid(),
  name: 'new pack',
  inputs: [
    {
      scope: 'productConfig',
      name: 'trim width',
      required: false,
    },
    {
      scope: 'productConfig',
      name: 'trim height',
      required: false,
    },
    {
      scope: 'productConfig',
      name: 'substrate weight',
      required: false,
    },
    {
      scope: 'productConfig',
      name: 'folded width',
      required: false,
    },
    {
      scope: 'productConfig',
      name: 'folded height',
      required: false,
    },
    {
      scope: 'productConfig',
      name: 'substrate thickness',
      required: false,
    },
    {
      scope: 'productConfig',
      name: 'lamination thickness',
      required: false,
    },
    {
      scope: 'productConfig',
      name: 'weight per piece',
      required: false,
    },
    {
      scope: 'pack',
      name: 'quantity',
      required: false,
    },
  ],
  packableQuantities: '',
  preferredPackQuantity: '',
  maxQuantity: '',
  shreddingStrategy: BIGGEST_PACKS,
  packsPerContainer: '',
  preferredContainerType: 'box',
  weightFormula: '',
  lengthFormula: '',
  widthFormula: '',
  heightFormula: '',
  diameterFormula: '',
  dimensionUnit: 'cm',
  weightUnit: 'g',
});
